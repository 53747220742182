// fetchWithJwt.js
export function fetchWithJwt(url, options = {}) {
  const defaultHeaders = options.headers || {};
  try {
    const userData = localStorage.getItem("userData");
    if (!userData) throw new Error('User data is not available.');

    const token = JSON.parse(userData).token;
    if (!token) {
      performLogout();
      throw new Error('Token is not available.');
    }

    const headersWithAuth = {
      ...defaultHeaders,
      Authorization: `Bearer ${token}`,
    };

    return fetch(url, { ...options, headers: headersWithAuth })
      .then(response => {
        if (response.status === 403 || response.status === 401) {
          // Jika response adalah 403 atau 401, lakukan logout
          performLogout();
        }
        return response;
      });
  } catch (error) {
    console.error(error);
    // Handle the error according to your application's needs
  }
}

// Fungsi untuk melakukan logout
function performLogout() {
  localStorage.removeItem("userData"); // Hapus userData dari localStorage
  window.location.href = "/auth/login"; // Redirect ke halaman login
}
