import React, { useEffect } from "react";
import { Routes, Route, Navigate, useNavigate } from "react-router-dom";
import Login from "views/login";
import Mobile from "layouts/Mobile";
import Device from "views/deviceShow/detail";
import Broadcast from "views/broadcast";
import NotFound from "views/notfound";
import Store from "views/store";
import LayoutWithHeader from "layouts/LayoutWithHeader";
import Account from "views/account";
import Logs from "views/log";
import Broadcst from "views/broadcast";

const PrivateRoute = ({ children }) => {
  const userData = localStorage.getItem("userData");
  return userData ? children : <Navigate to="/auth/login" replace />;
};

const App = () => {
  return (
    <Routes>
      {/* Rute Publik */}
      <Route path="auth/login" element={<Login />} />

      {/* Rute Privat */}
      <Route
        path="mobile"
        element={
          <PrivateRoute>
            <Mobile />
          </PrivateRoute>
        }
      />
      <Route
        path="device/:id"
        element={
          <PrivateRoute>
            <Device />
          </PrivateRoute>
        }
      />
      <Route
        path="store"
        element={
          <PrivateRoute>
            <LayoutWithHeader title="Store">
              <Store />
            </LayoutWithHeader>
          </PrivateRoute>
        }
      />
      <Route
        path="account"
        element={
          <PrivateRoute>
            <LayoutWithHeader title="Akun">
              <Account />
            </LayoutWithHeader>
          </PrivateRoute>
        }
      />
      <Route
        path="logs"
        element={
          <PrivateRoute>
            <LayoutWithHeader title="Logs">
              <Logs />
            </LayoutWithHeader>
          </PrivateRoute>
        }
      />
      <Route
        path="broadcast"
        element={
          <PrivateRoute>
            <LayoutWithHeader title="Broadcast">
              <Broadcast />
            </LayoutWithHeader>
          </PrivateRoute>
        }
      />

      {/* Redirect Default */}
      <Route path="/" element={<Navigate to="/mobile" replace />} />

      {/* Fallback untuk Rute Tidak Dikenal */}
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default App;
