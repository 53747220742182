import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGear, faHome, faUser } from "@fortawesome/free-solid-svg-icons";
import Home from '../views/home';
import Setting from '../views/setting';

const MobileLayout = () => {
  const [activeTab, setActiveTab] = useState('Tab1');
  const navigate = useNavigate();

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const IconButton = ({ icon, label, onClick, isActive }) => (
    <button onClick={onClick} aria-label={label} className="p-4 relative icon-button-relative">
      <div className="flex flex-col items-center">
        <div className="relative">
          <FontAwesomeIcon
            icon={icon}
            className={`text-lg ${isActive ? 'text-blue-500' : 'text-gray-500'}`} // Ubah warna berdasarkan aktif atau tidak
          />
        </div>
        <span className={`mt-2 text-sm ${isActive ? 'text-blue-500' : 'text-gray-500'}`}>{label}</span>
      </div>
    </button>
  );

  return (
    <div className="h-screen">
      <main className="overflow-auto">
        <div className='mb-20 p-2'>
          {activeTab === 'Tab1' && <Home />}
          {activeTab === 'Tab2' && <Setting />}
        </div>
      </main>
      <div className="fixed bottom-0 left-0 w-full flex justify-around md:justify-center md:gap-10 bg-white shadow-md">
        <IconButton
          icon={faHome}
          label="Home"
          onClick={() => handleTabClick('Tab1')}
          isActive={activeTab === 'Tab1'}
        />
        <IconButton
          icon={faGear}
          label="Setting"
          onClick={() => handleTabClick('Tab2')}
          isActive={activeTab === 'Tab2'}
        />
      </div>
    </div>
  );
};

export default MobileLayout;
