import React from "react";
import { MdArrowBack } from "react-icons/md";
import { useNavigate } from "react-router-dom";

const HeaderWithBackButton = ({ title, children }) => {
  const navigate = useNavigate();

  return (
    <>
      <header className="bg-white fixed top-0 left-0 right-0 shadow-md p-4 flex items-center justify-between sm:justify-between flex-wrap">
        {/* Bagian Kiri: Tombol Back dan Judul */}
        <div className="flex items-center">
          <button
            onClick={() => (window.history.length > 2 ? navigate(-1) : navigate("/"))}
            className="text-blue-600 hover:text-blue-800 transition"
          >
            <MdArrowBack className="h-6 w-6" />
          </button>
          <h1 className="ml-4 text-lg font-semibold text-gray-800">{title}</h1>
        </div>
      </header>
      <div className="pt-16 min-h-screen ">{children}</div>
    </>
  );
};

export default HeaderWithBackButton;
