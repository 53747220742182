import React, { useState, useEffect } from "react";
import { fetchWithJwt } from "components/fetchWithJwt";
import Swal from "sweetalert2";

const Store = () => {
  const API_DOMAIN = process.env.REACT_APP_API_DOMAIN;
  const [dataAccount, setDataAccount] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedStore, setSelectedStore] = useState(null); // Untuk menyimpan store yang dipilih
  const [formData, setFormData] = useState({
    email: "",
    uid: "",
    client_id: "",
    secret_key: ""
  });

  // Ambil data account dari API
  const fetchAccountData = async () => {
    try {
      const response = await fetchWithJwt(`${API_DOMAIN}/account`);
      if (!response.ok) throw new Error(`HTTP error! Status: ${response.status}`);
      const data = await response.json();
      if (Array.isArray(data)) {
        setDataAccount(data);
        setFilteredData(data);
      } else {
        throw new Error("Data tidak valid");
      }
    } catch (error) {
      console.error("Error fetching store data:", error);
    }
  };

  // Tambahkan data store baru
  const addAccount = async () => {
    try {
      // apakah data lengkap
      if (!formData.email || !formData.uid || !formData.client_id || !formData.secret_key) {
        throw new Error("Data tidak lengkap");
      }
      const response = await fetchWithJwt(`${API_DOMAIN}/account`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });
      if (!response.ok) throw new Error(`HTTP error! Status: ${response.status}`);
      setIsModalOpen(false);
      Swal.fire({
        icon: "success",
        title: "Store berhasil ditambahkan",
        showConfirmButton: false,
        timer: 1500,
      });
      fetchAccountData();
      setFormData({
        email: "",
        uid: "",
        client_id: "",
        secret_key: ""
      });
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Store gagal ditambahkan",
        showConfirmButton: false,
        timer: 1500,
      });
      console.error("Error adding store:", error);
    }
  };

  // Handle perubahan pada form
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData
    }));
  };

  // Fungsi untuk menangani klik pada store
  const handleStoreClick = (store) => {
    setSelectedStore(store);
  };

  // Fungsi debounced search
  const searchHandle = (value) => {
    setSearchValue(value);
    const filtered = dataAccount.filter((item) =>
      item.email.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredData(filtered);
  };

  useEffect(() => {
    fetchAccountData();
  }, []);

  return (
    <div className="p-4">
      {/* Button Tambah Store */}
      <button
        onClick={() => setIsModalOpen(true)}
        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mb-4"
      >
        Tambah Akun
      </button>

      {/* Input Pencarian */}
      <input
        value={searchValue}
        onChange={(e) => searchHandle(e.target.value)}
        type="text"
        placeholder="Cari..."
        className="w-full p-2 mb-4 border rounded"
      />

      {/* Daftar Akun */}
      {filteredData.length > 0 ? (
        filteredData.map((item, index) => (
          <div
            onClick={() => handleStoreClick(item)}
            key={index}
            className="p-4 border border-gray-200 mb-2 rounded cursor-pointer hover:bg-gray-100"
          >
            <p>{item.email}</p>
          </div>
        ))
      ) : (
        <p className="text-center text-gray-500">Tidak ada data</p>
      )}
  
      {/* Modal Tambah Akun */}
      {isModalOpen && (
        <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white w-full max-w-md p-4 rounded">
            <h2 className="text-lg font-bold mb-4">Tambah Akun</h2>
            <div className="flex flex-col gap-4">
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                placeholder="Email"
                className="w-full p-2 mb-2 border rounded"
              />
              <input
                type="text"
                name="uid"
                value={formData.uid}
                onChange={handleChange}
                placeholder="UID"
                className="w-full p-2 mb-2 border rounded"
              />
              <input
                type="text"
                name="client_id"
                value={formData.client_id}
                onChange={handleChange}
                placeholder="Client ID"
                className="w-full p-2 mb-2 border rounded"
              />
              <input
                type="text"
                name="secret_key"
                value={formData.secret_key}
                onChange={handleChange}
                placeholder="Secret Key"
                className="w-full p-2 mb-2 border rounded"
              />
              <button
                onClick={addAccount}
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
              >
                Simpan
              </button>
              <button
                onClick={() => setIsModalOpen(false)}
                className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded"
              >
                Batal
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Modal Detail Store */}
      {selectedStore && (
        <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white w-full max-w-md p-4 rounded">
            <h2 className="text-lg font-bold mb-4">Detail Store</h2>
            <div className="flex flex-col gap-4">
              <div className="flex flex-row">
                <div className="w-1/3">Email</div>
                <div className="w-2/3 font-bold">{selectedStore.email}</div>
              </div>
              <div className="flex flex-row">
                <div className="w-1/3">Status</div>
                <div className="w-2/3 font-bold">Aktif</div>
              </div>
              <button
                onClick={() => setSelectedStore(null)}
                className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded mt-4"
              >
                Tutup
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Store;
