import React, { useState, useEffect } from "react";
import { fetchWithJwt } from "components/fetchWithJwt";
import Swal from "sweetalert2";

const Broadcast = () => {
  const API_DOMAIN = process.env.REACT_APP_API_DOMAIN;
  const [dataBroadcast, setDataBroadcast] = useState([]);
  const [dataStore, setDataStore] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedStore, setSelectedStore] = useState(null); // Store selected broadcast
  const [formData, setFormData] = useState({
    id_store: "",
    jam_mulai: "",
    jam_selesai: "",
    code: "",
    value: "",
  });

  // Fetch broadcast and store data
  const fetchBroadcastData = async () => {
    try {
      const response = await fetchWithJwt(`${API_DOMAIN}/broadcast`);
      if (!response.ok) throw new Error(`HTTP error! Status: ${response.status}`);
      const data = await response.json();
      setDataBroadcast(data.data_broadcast);
      setDataStore(data.data_store);
      setFilteredData(data.data_broadcast);
    } catch (error) {
      console.error("Error fetching store data:", error);
    }
  };

  // Add new broadcast data
  const addBroadcast = async () => {
    try {
      if (!formData.id_store || !formData.jam_mulai || !formData.jam_selesai || !formData.code || !formData.value) {
        throw new Error("Data tidak lengkap");
      }

      const response = await fetchWithJwt(`${API_DOMAIN}/broadcast`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      if (!response.ok) throw new Error(`HTTP error! Status: ${response.status}`);

      setIsModalOpen(false);
      Swal.fire({
        icon: "success",
        title: "Broadcast berhasil ditambahkan",
        showConfirmButton: false,
        timer: 1500,
      });

      fetchBroadcastData(); // Refresh broadcast data
      setFormData({
        id_store: "",
        jam_mulai: "",
        jam_selesai: "",
        code: "",
        value: "",
      });
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Broadcast gagal ditambahkan",
        showConfirmButton: false,
        timer: 1500,
      });
      console.error("Error adding broadcast:", error);
    }
  };

  // Handle form data changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // Handle store selection
  const handleStoreClick = (store) => {
    setSelectedStore(store); // This should be the full store object
  };

  // Handle search filter
  const searchHandle = (value) => {
    setSearchValue(value);
    const filtered = dataBroadcast.filter((item) =>
      item.store.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredData(filtered);
  };

  useEffect(() => {
    fetchBroadcastData();
  }, []);

  const formatTime = (time) => {
    return time ? time.substring(0, 5) : "";
  };

  return (
    <div className="p-4">
      {/* Button to open modal */}
      <button
        onClick={() => setIsModalOpen(true)}
        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mb-4"
      >
        Tambah Broadcast
      </button>

      {/* Search input */}
      <input
        value={searchValue}
        onChange={(e) => searchHandle(e.target.value)}
        type="text"
        placeholder="Cari..."
        className="w-full p-2 mb-4 border rounded"
      />

      {/* Display broadcast list */}
      {filteredData.length > 0 ? (
        filteredData.map((item, index) => (
          <div
            onClick={() => handleStoreClick(item)}
            key={index}
            className="p-4 border border-gray-200 mb-2 rounded cursor-pointer hover:bg-gray-100"
          >
            <p>{item.nama_store}</p>
          </div>
        ))
      ) : (
        <p className="text-center text-gray-500">Tidak ada data</p>
      )}

      {/* Modal Add Broadcast */}
      {isModalOpen && (
        <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white w-full max-w-md p-4 rounded">
            <h2 className="text-lg font-bold mb-4">Tambah Broadcast</h2>
            <div className="flex flex-col gap-4">
              <label htmlFor="id_store">Store</label>
              <select
                name="id_store"
                value={formData.id_store}
                onChange={handleChange}
                className="w-full p-2 mb-2 border rounded"
              >
                <option value="">Pilih Store</option>
                {dataStore.map((item, index) => (
                  <option key={index} value={item.id}>
                    {item.nama}
                  </option>
                ))}
              </select>

              <label htmlFor="jam_mulai">Jam Mulai</label>
              <input
                type="time"
                name="jam_mulai"
                value={formData.jam_mulai}
                onChange={handleChange}
                className="w-full p-2 mb-2 border rounded"
              />

              <label htmlFor="jam_selesai">Jam Selesai</label>
              <input
                type="time"
                name="jam_selesai"
                value={formData.jam_selesai}
                onChange={handleChange}
                className="w-full p-2 mb-2 border rounded"
              />

              <label htmlFor="code">Code</label>
              <select
                name="code"
                value={formData.code}
                onChange={handleChange}
                className="w-full p-2 mb-2 border rounded"
              >
                <option value="">Pilih Code</option>
                <option value="T">Set Temperature</option>
                <option value="M">Set Mode</option>
                <option value="W">Set Wind</option>
              </select>

              <label htmlFor="value">Value</label>
              <input
                type="text"
                name="value"
                value={formData.value}
                onChange={handleChange}
                className="w-full p-2 mb-2 border rounded"
              />

              <button
                onClick={addBroadcast}
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
              >
                Simpan
              </button>

              <button
                onClick={() => setIsModalOpen(false)}
                className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded"
              >
                Batal
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Modal Detail Store */}
      {selectedStore && (
        <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white w-full max-w-md p-4 rounded">
            <h2 className="text-lg font-bold mb-4">Detail Broadcast</h2>
            <div className="flex flex-col gap-4">
              <div className="flex flex-row">
                <div className="w-1/3">Store</div>
                <div className="w-2/3 font-bold">{selectedStore.nama_store}</div>
              </div>
              <div className="flex flex-row">
                <div className="w-1/3">Code</div>
                <div className="w-2/3 font-bold">{selectedStore.code}</div>
              </div>
              <div className="flex flex-row">
                <div className="w-1/3">Value</div>
                <div className="w-2/3 font-bold">{selectedStore.value}</div>
              </div>
              <div className="flex flex-row">
                <div className="w-1/3">Jam Mulai</div>
                <div className="w-2/3 font-bold">{formatTime(selectedStore.jam_mulai)}</div>
              </div>
              <div className="flex flex-row">
                <div className="w-1/3">Jam Selesai</div>
                <div className="w-2/3 font-bold">{formatTime(selectedStore.jam_selesai)}</div>
              </div>
              <button
                onClick={() => setSelectedStore(null)}
                className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded mt-4"
              >
                Tutup
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Broadcast;
