import React, { useState, useEffect, useCallback } from 'react';
import { fetchWithJwt } from "components/fetchWithJwt";
import { PhotoProvider, PhotoView } from 'react-photo-view';
import { Link, useNavigate } from 'react-router-dom';
import { MdMap, MdTimer, MdVisibility } from 'react-icons/md';
import Modal from 'react-modal';
import 'react-photo-view/dist/react-photo-view.css';

const Store = () => {
  const API_DOMAIN = process.env.REACT_APP_API_DOMAIN;
  const [storeData, setStoreData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [layoutData, setLayoutData] = useState([]);
  const [nameStore, setNameStore ] = useState('');
  const [abnormalData, setAbnormalData] = useState(0);
  const [offlineData, setOfflineData] = useState(0);

  Modal.setAppElement('#root'); // Aksesibilitas untuk modal

  const openModal = (layout, store) => {
    setLayoutData(layout);
    setNameStore(store);
    setIsModalOpen(true);
    document.body.style.overflow = 'hidden';
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setLayoutData([]);
    document.body.style.overflow = 'auto';
  };

  const fetchData = useCallback(async () => {
    try {
      const response = await fetchWithJwt(`${API_DOMAIN}/device/show`);
      if (!response.ok) throw new Error(`Failed to fetch data: ${response.status} - ${response.statusText}`);
      const data = await response.json();
      setStoreData(data.data);
      setFilteredData(data.data);
    } catch (error) {
      console.error("Error fetching store data:", error);
    }
  }, [API_DOMAIN]);

  const searchHandle = (value) => {
    setSearchQuery(value);
    const filtered = storeData.filter((sensor) =>
      sensor.name.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredData(filtered);
  };

  const navigate = useNavigate();

  const goToDevice = (deviceId) => {
    navigate(`/device/${deviceId}`);
  };

  const formatTime = (jam_buka, jam_tutup) => {
    if (jam_buka && jam_tutup) {
      return `${jam_buka.split(":").slice(0, 2).join(":")} - ${jam_tutup.split(":").slice(0, 2).join(":")}`;
    } else {
      return "24 Jam";
    }
  };

  const renderColor = (value) => {
    if (value / 10 > 26) return 'bg-red-500';
    if (value / 10 < 23) return 'bg-blue-500';
    return 'bg-green-500';
  };

  const renderWindSpeed = (value) => {
    if (value === '0') return 'Auto';
    if (value === '1') return 'Low';
    if (value === '2') return 'Medium';
    if (value === '3') return 'High';
    return '';
  };

  const renderMode = (value) => {
    if (value === '0') return 'Cold';
    if (value === '1') return 'Hot';
    if (value === '2') return 'Auto';
    if (value === '3') return 'Speed';
    return '';
  };

  useEffect(() => {
    fetchData();
    const interval = setInterval(fetchData, 60000); // Fetch setiap 1 menit
    return () => clearInterval(interval);
  }, [fetchData]);

  // hitung data abnormal dari data sensor
  const countAbnormalData = () => {
    let count = 0;
    filteredData.forEach((store) => {
        store.alat.forEach((alat) => {
            const vaStatus = alat.status.find((stat) => stat.code === 'va_temperature');
            if (vaStatus && (vaStatus.value < 230 || vaStatus.value > 260)) {
                count += 1;
            }
        });
    });
    setAbnormalData(count);
  };

  const hitungOfflineData = () => {
    let count = 0;
    filteredData.forEach((store) => {
        store.alat.forEach((alat) => {
            const vaStatus = alat.online;
            if (vaStatus === 0) {
                count += 1;
            }
        });
    });
    setOfflineData(count);
  };

  useEffect(() => {
    countAbnormalData();
  }, [filteredData]);

  return (
    <div className="store-container flex flex-wrap justify-center">
      <div className="fixed top-0 left-0 p-3 w-full flex justify-center">
        <input
          value={searchQuery}
          onChange={(e) => searchHandle(e.target.value)}
          type="text"
          placeholder="Cari..."
          className="border hover:bg-opacity-100 border-gray-300 bg-white focus:bg-opacity-100 bg-opacity-50 rounded-xl w-full max-w-4xl p-5"
        />
      </div>
      {/* <div className="md:w-1/3 w-full flex flex-col gap-4 items-center p-1 mt-20">
        Jumlah alat offline
      </div> */}
      <div className="md:w-2/3 w-full flex flex-col gap-4 items-center mt-20">
        {filteredData.map((store) => (
          <div key={store.id} className="flex flex-col shadow-md bg-white rounded-md gap-2 w-full max-w-4xl p-2">
            <div className="flex flex-col items-center gap-3">
              <div className="text-xl font-bold">{store.name}</div>
              <div className="flex flex-row gap-1 text-sm items-center">
                <MdTimer />
                <div>{formatTime(store.jam_buka, store.jam_tutup)}</div>
              </div>
              <div>
                <button
                  onClick={() => openModal(store.data_layout, store.name)}
                  className="text-blue-500 hover:text-blue-600"
                >
                  Layout
                </button>
              </div>
            </div>
            <div className="w-full overflow-x-auto text-sm">
              <table className="w-full">
                <thead className='border bg-gray-300 text-center'>
                    <tr>
                      <th rowSpan={2} className='row-span-2 border-r'>Nomor AC</th>
                      <th rowSpan={2} className='row-span-2 border-r'>Kelembaban</th>
                      <th rowSpan={2} className='row-span-2 border-r'>Suhu</th>
                      <th colSpan={3} className='text-center hidden md:table-cell border-b border-gray-200'>Remote</th>
                    </tr>
                    <tr>
                      <th className='border-r'>Suhu Remote</th>
                      <th className='border-r hidden md:table-cell'>Fan Speed</th>
                      <th className='border-r hidden md:table-cell'>Mode</th>
                    </tr>
                  </thead>
                <tbody>
                  {store.alat.map((alat) => (
                    <tr
                      key={alat.device_id}
                      onClick={() => goToDevice(alat.device_id)}
                      className="hover:bg-gray-100 cursor-pointer border border-gray-200 h-12 text-center"
                    >
                      <td>AC{alat.name.slice(5, 7)}</td>
                      <td>{alat.status.find((stat) => stat.code === 'va_humidity').value}%</td>
                      <td
                        className={
                          alat.online
                            ? renderColor(alat.status.find((stat) => stat.code === 'va_temperature').value)
                            : 'bg-gray-400'
                        }
                      >
                        {alat.status.find((stat) => stat.code === 'va_temperature').value / 10}
                      </td>
                      <td>{alat.remote_status?.find((stat) => stat.code === 'temp')?.value}</td>
                      <td className="hidden md:table-cell">
                        {renderWindSpeed(alat.remote_status?.find((stat) => stat.code === 'wind')?.value)}
                      </td>
                      <td className="hidden md:table-cell">
                        {renderMode(alat.remote_status?.find((stat) => stat.code === 'mode')?.value)}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        ))}
      </div>

      {/* Modal Component */}
      {isModalOpen && (
        <PhotoProvider>
          <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
            <div className="bg-white rounded p-4 max-w-2xl w-full overflow-y-auto m-4">
              <h2 className="text-lg font-bold mb-4">{nameStore}</h2>
              <p className="text-sm mb-4 text-black-600">Klik pada gambar untuk memperbesar gambar</p>
              <div className='flex flex-wrap gap-2'>
                {layoutData.map((layout, index) => (
                  <div>
                  <PhotoView key={index} src={`${API_DOMAIN}/img/layout/${layout.nama_file}`}>
                    <img
                      src={`${API_DOMAIN}/img/layout/${layout.nama_file}`}
                      alt={layout.nama_file}
                      className="h-20 w-20 hover:scale-110 cursor-pointer shadow-md rounded-lg"
                    />
                  </PhotoView>
                  <p className='text-sm text-center'>{index + 1}</p>
                  </div>
                ))}
              </div>
              <div className="flex justify-end">
                <button
                  onClick={closeModal}
                  className="px-4 py-2 bg-red-500 hover:bg-opacity-80 text-white rounded mt-4"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </PhotoProvider>
      )}
      </div>
  );
};

export default Store;
