import { MdInfo, MdVisibility, MdVisibilityOff } from "react-icons/md";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const API_DOMAIN = process.env.REACT_APP_API_DOMAIN;

const Login = () => {
  const navigate = useNavigate();
  const [alert, setAlert] = useState(null);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [passwordVisible, setPasswordVisible] = useState(false);

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  useEffect(() => {
    if (alert) {
      setTimeout(() => {
        setAlert(null);
      }, 5000);
    }
  }, [alert]);

  const handleSubmit = async () => {
    try {
      const body = { username: username, password: password };

      const response = await fetch(API_DOMAIN + "/login/auth", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      });

      const responseData = await response.json();

      if (!response.ok) {
        setAlert({
          type: "error",
          message: responseData.message,
        });
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      setAlert({
        type: "success",
        message: responseData.message,
      });

      localStorage.setItem("userData", JSON.stringify(responseData));
      navigate("/mobile");

      console.log("Perintah sukses dikirim:", responseData);
    } catch (error) {
      console.error("Error mengirim perintah:", error);
    }
  };

  return (
    <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
      {alert && (
        <div
          className={`mb-4 ${
            alert.type === "success" ? "bg-green-100" : "bg-red-100"
          } border-l-4 border-${
            alert.type === "success" ? "green" : "red"
          }-500 text-${
            alert.type === "success" ? "green" : "red"
          }-700 rounded p-4`}
          role="alert"
        >
          <MdInfo className="mr-2 inline" />
          <label>{alert.message}</label>
        </div>
      )}
      <div className="sm:mx-auto sm:w-full sm:max-w-sm">
        <div className="mt-10 text-center">
          <label className="text-3xl font-bold text-indigo-600">
            NICO URBAN INDONESIA
          </label>
          <br />
          <label className="font-bold text-indigo-600">Air System Expert</label>
        </div>
      </div>

      <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
        <div className="space-y-6">
          <div>
            <label
              htmlFor="username"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Username
            </label>
            <div className="mt-2">
              <input
                type="text"
                id="username"
                name="username"
                className="block w-full rounded-md border-0 py-2 pl-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                required
              />
            </div>
          </div>

          <div>
            <label
              htmlFor="password"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Password
            </label>
            <div className="mt-2 relative">
              <input
                id="password"
                name="password"
                type={passwordVisible ? "text" : "password"}
                className="block w-full rounded-md border-0 py-2 pl-2 pr-10 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <button
                onClick={togglePasswordVisibility}
                className="absolute inset-y-0 right-0 pr-3 flex items-center text-sm text-gray-600 hover:text-gray-900"
              >
                {passwordVisible ? <MdVisibilityOff /> : <MdVisibility />}
              </button>
            </div>
          </div>

          <div>
            <button
              onClick={handleSubmit}
              className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              SIGN IN
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
