import Card from "components/card";
import { fetchWithJwt } from "components/fetchWithJwt";
import { useState, useEffect, useCallback } from "react";
import { MdOutlinePowerSettingsNew, MdOutlinePowerOff, MdThermostat, MdOutlineSignalCellularAlt, MdOutlineEmergency, MdCancel } from "react-icons/md";

const DeviceControlRemote = ({ deviceId }) => {
  const API_DOMAIN = process.env.REACT_APP_API_DOMAIN;
  
  const [loading, setLoading] = useState(false);
  const [acStatus, setAcStatus] = useState(null);
  const [showTemp, setShowTemp] = useState(false);
  const [showMode, setShowMode] = useState(false);
  const [showWind, setShowWind] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);

  const handleShowTemp = () => { setShowTemp(!showTemp); setShowMode(false); setShowWind(false); };
  const handleShowMode = () => { setShowTemp(false); setShowMode(!showMode); setShowWind(false); };
  const handleShowWind = () => { setShowTemp(false); setShowMode(false); setShowWind(!showWind); };

  const getModeText = (modeValue) => {
    switch (modeValue) {
      case "0": return "COLD";
      case "1": return "HOT";
      case "2": return "AUTO";
      case "3": return "SPEED";
      case "4": return "DEHUMY";
      default: return "UNKNOWN";
    }
  };

  const getWindText = (windValue) => {
    switch (windValue) {
      case "0": return "AUTO";
      case "1": return "LOW";
      case "2": return "MIDDLE";
      case "3": return "HIGH";
      default: return "UNKNOWN";
    }
  };

  const sendCommand = async (code, value) => {
    try {
      const body = { commands: [{ code: code === 'temp' ? 'T' : (code === 'mode' ? 'M' : (code === 'wind' ? 'F' : code)), value }] };
      const response = await fetchWithJwt(`${API_DOMAIN}/device/control/${deviceId}`, {method: 'POST', body: JSON.stringify(body), headers: { 'Content-Type': 'application/json' }});
      if (response.ok) {fetchAcStatus()}
    } catch (error) {
      window.alert(`${error.message}`);
    }
  };

  const handlePowerClick = async (action) => {
    const confirmation = window.confirm(`You want to turn ${action} the power?`);
    if (!confirmation) return;
    setIsUpdating(true);
    try {
      await fetchWithJwt(`${API_DOMAIN}/device/control/${action}/${deviceId}`, { method: "GET", headers: { "Content-Type": "application/json" } });
      fetchAcStatus();
    } catch (error) {
      window.alert(`${error.message}`);
    } finally {
      setIsUpdating(false);
    }
  };

  

  const fetchAcStatus = async () => {
    setLoading(true);
    try {
      const response = await fetchWithJwt(`${API_DOMAIN}/device/status/${deviceId}`);
      const data = await response.json();
      if (data.success) {
        const statusObject = {};
        data.result.forEach(item => { statusObject[item.code] = item.value; });
        setAcStatus(statusObject);
      }
    } catch (error) {
      console.log((`Error fetching data: ${error.message}`));
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAcStatus();
  }, [ deviceId ]);
  

  const RemoteTemperatureSet = ({ type }) => {
    const [value, setValue] = useState(acStatus?.[type] || '');
    const handleChange = (e) => {setValue(e.target.value)};
    const handleSubmit = () => {
      switch (type) {
        case 'temp':
          if (value >= 18 && value <= 30) sendCommand('temp', value.toString());
          break;
        case 'mode':
          if (value >= 0 && value <= 4) sendCommand('mode', value.toString());
          break;
        case 'wind':
          if (value >= 0 && value <= 3) sendCommand('wind', value.toString());
          break;
        default:
          break;
      }
    };
    return (
      <Card>
        <select value={value} onChange={handleChange} style={{ color: 'black' }}>
          {type === 'temp' && Array.from({ length: 14 }, (_, i) => 18 + i).map(temp => (
            <option key={temp} value={temp}>{temp}°C</option>
          ))}
          {type === 'mode' && (
            <>
              <option value="0">COLD</option>
              <option value="1">HOT</option>
              <option value="2">AUTO</option>
              <option value="3">SPEED</option>
              <option value="4">DEHUMY</option>
            </>
          )}
          {type === 'wind' && (
            <>
              <option value="0">AUTO</option>
              <option value="1">LOW</option>
              <option value="2">MIDDLE</option>
              <option value="3">HIGH</option>
            </>
          )}
        </select>
        <button onClick={handleSubmit} style={{ fontSize: '12px' }} className="p-2 mt-2 bg-gray-500 hover:bg-blue-500 rounded font-bold text-white">OK</button>
      </Card>
    );
  };

  if (loading) {
    return (
      <Card extra="mt-5 items-center">
        <div className="text-xl font-bold text-navy-700 dark:text-white">Loading...</div>
      </Card>
    );
  }

  return (
    <div className="mt-2 p-2 shadow rounded-lg bg-blue-500">
      <div className="flex-col">
        {acStatus && (
          <>
          <div className="mt-2 rounded md:flex border-gray-500 flex flex-col lg:flex-row justify-between items-center gap-5 p-3 text-white">
            <div>
              <h1 className="mb-2">POWER: {acStatus.power === "1" ? "ON" : "OFF"}</h1>
              <div className="flex flex-col items-center p-5 w-40 rounded-xl text-blue-500 bg-white hover:bg-gray-300">
                  <button onClick={() => handlePowerClick('on')} disabled={isUpdating}><MdOutlinePowerSettingsNew className="w-20 h-20"/></button>
                  <h1 className="text-center">POWER ON</h1>
              </div>
            </div>
            <div>
              {/* <h1 className="mb-2">POWER: {acStatus.power === "1" ? "ON" : "OFF"}</h1> */}
              <div className="flex flex-col items-center p-5 w-40 rounded-xl text-blue-500 bg-white hover:bg-gray-200">
                  <button onClick={() => handlePowerClick('off')} disabled={isUpdating}><MdOutlinePowerOff className="w-20 h-20" /></button>
                  <h1 className="text-center">POWER OFF</h1>
              </div>
            </div>
            <div>
              <h1 className="mb-2 text-center">TEMPERATURE: {acStatus.temp}°C</h1>
              <div className="flex flex-col items-center p-5 w-40 rounded-xl text-blue-500 bg-white hover:bg-gray-200">
                  <button onClick={() => handleShowTemp()}><MdThermostat className="w-20 h-20" /></button>
                  <h1 className="text-center">TEMPERATURE</h1>
                {showTemp && <RemoteTemperatureSet type="temp"/>}
              </div>
            </div>
            <div>
              <h1 className="mb-2 text-center">MODE: {getModeText(acStatus.mode)}</h1>
              <div className="flex flex-col items-center p-5 w-40 rounded-xl text-blue-500 bg-white hover:bg-gray-200">
                  <button onClick={() => handleShowMode()}><MdOutlineEmergency  className="w-20 h-20" /></button>
                  <h1 className="text-center">MODE</h1>
                {showMode && <RemoteTemperatureSet type="mode"/>}
              </div>
            </div>
            <div>
              <h1 className="mb-2 text-center">SPEED: {getWindText(acStatus.wind)}</h1>
              <div className="flex flex-col items-center p-5 w-40 rounded-xl text-blue-500 bg-white hover:bg-gray-200">
                  <button onClick={() => handleShowWind()}><MdOutlineSignalCellularAlt  className="w-20 h-20" /></button>
                  <h1 className="text-center">SPEED</h1>
                {showWind && <RemoteTemperatureSet type="wind"/>}
              </div>
            </div>
          </div>
          </>
        )}
        
      </div>
    </div>
  );
};

export default DeviceControlRemote;
